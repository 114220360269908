<template>
  <div class="competition-configuration-table">
    <div class="competition-configuration-table__head">
      <div class="competition-configuration-table__row">
        <div
          :class="[
            'competition-configuration-table__cell',
            'competition-configuration-table__cell--type-competition',
          ]"
        />
        <div
          v-for="feed in feeds"
          :key="feed"
          :class="[
            'competition-configuration-table__cell',
            'competition-configuration-table__cell--type-feed',
          ]"
        >
          {{ getFeedName(feed) }}
        </div>
      </div>
      <div class="competition-configuration-table__row">
        <div
          :class="[
            'competition-configuration-table__cell',
            'competition-configuration-table__cell--type-competition',
          ]"
        >
          <div class="competition-configuration-table__sort-cell">
            <CompetitionConfigurationTableSort
              :model-value="sort"
              @update:model-value="updateSort"
            />
            Competition
          </div>
        </div>
        <div
          v-for="feedColumn in feedColumns"
          :key="feedColumn.id"
          :class="[
            'competition-configuration-table__cell',
            `competition-configuration-table__cell--type-${feedColumn.type}`,
          ]"
        >
          {{ feedColumn.label }}
        </div>
      </div>
    </div>
    <div class="competition-configuration-table__body">
      <div
        v-for="competition in competitions"
        :key="competition.competitionId"
        class="competition-configuration-table__row"
      >
        <div
          :class="[
            'competition-configuration-table__cell',
            'competition-configuration-table__cell--type-competition',
          ]"
        >
          <span class="competition-configuration-table__label">
            {{ competition.competitionName }}
          </span>
          <span
            :class="[
              'competition-configuration-table__label',
              'competition-configuration-table__label--is-small',
              'competition-configuration-table__label--is-muted',
            ]"
          >
            {{ competition.regionName }}
          </span>
        </div>
        <div
          v-for="feedColumn in feedColumns"
          :key="feedColumn.id"
          :class="[
            'competition-configuration-table__cell',
            `competition-configuration-table__cell--type-${feedColumn.type}`,
          ]"
        >
          <CompetitionConfigurationPriorityField
            v-if="feedColumn.type === 'priority'"
            :key="getConfigurationPriority(`${competition.competitionId}:${feedColumn.feed}`)"
            variant="table"
            :model-value="getConfigurationPriority(`${competition.competitionId}:${feedColumn.feed}`)"
            @update:model-value="(priority) => updateConfiguration(`${competition.competitionId}:${feedColumn.feed}`, { priority })"
            :max="9"
            :is-placeholder="!hasConfiguration(`${competition.competitionId}:${feedColumn.feed}`)"
          />
          <span
            v-else-if="hasConfiguration(`${competition.competitionId}:${feedColumn.feed}`)"
            class="competition-configuration-table__label competition-configuration-table__label--is-clickable"
            @click="editConfiguration(`${competition.competitionId}:${feedColumn.feed}`)"
          >
            {{ getConfigurationMarkets(`${competition.competitionId}:${feedColumn.feed}`) }}
          </span>
          <Button
            v-else
            icon="plus"
            @click="createConfiguration(competition.competitionId, competition.competitionName, feedColumn.feed)"
          >
            Configuration
          </Button>
        </div>
      </div>
      <CompetitionConfigurationModal
        v-if="workingConfiguration"
        v-model:configuration="workingConfiguration"
        :original-configuration="originalConfiguration"
        :feeds="feeds"
        @submit="saveConfiguration"
        @close="closeConfiguration"
      />
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  capitalize,
  join,
  map,
} from 'lodash';
import { ref, computed } from 'vue';
import { allMarketCategories, makeFeedColumns, sortMarketCategoryCodes } from '@/services/helpers/competition-configuration';
import Button from '@/components/common/Button';
import CompetitionConfigurationTableSort from './CompetitionConfigurationTableSort';
import CompetitionConfigurationPriorityField from './CompetitionConfigurationPriorityField';
import CompetitionConfigurationModal from './CompetitionConfigurationModal';

export default {
  components: {
    Button,
    CompetitionConfigurationTableSort,
    CompetitionConfigurationPriorityField,
    CompetitionConfigurationModal,
  },
  props: {
    feeds: {
      type: Array,
      required: true,
    },
    competitions: {
      type: Array,
      required: true,
    },
    configurations: {
      type: Object,
      required: true,
    },
    sort: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:configurations': {
      type: Object,
    },
    'update:sort': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const originalConfiguration = ref(null);
    const workingConfiguration = ref(null);

    const feedColumns = computed(() => makeFeedColumns(props.feeds));

    const getFeedName = (feed) => capitalize(feed);
    const hasConfiguration = (id) => !!props.configurations?.[id];
    const getConfigurationPriority = (id) => props.configurations?.[id]?.priority;
    const getConfigurationMarkets = (id) => {
      const markets = props.configurations?.[id]?.marketCategories || [];
      if (!markets.length) return '-';
      const orderedMarkets = sortMarketCategoryCodes(markets);
      return join(map(orderedMarkets, (market) => allMarketCategories?.[market]?.shortName || 'N/A'), ', ');
    };
    const updateSort = (newSort) => {
      emit('update:sort', newSort);
    };
    const updateConfiguration = (id, newFields = {}) => {
      emit('update:configurations', {
        ...props.configurations,
        [id]: {
          ...(props.configurations[id] || {}),
          ...newFields,
        },
      });
    };
    const createConfiguration = (competitionId, competitionName, feed) => {
      originalConfiguration.value = null;
      workingConfiguration.value = {
        competitionId,
        competitionName,
        coverageExpected: false,
        feed,
        id: `${competitionId}:${feed}`,
        marketCategories: [],
        priority: 1,
      };
    };
    const closeConfiguration = () => {
      originalConfiguration.value = null;
      workingConfiguration.value = null;
    };
    const editConfiguration = (id) => {
      originalConfiguration.value = props.configurations[id];
      workingConfiguration.value = cloneDeep(props.configurations[id]);
    };
    const saveConfiguration = () => {
      if (!workingConfiguration.value) return;
      updateConfiguration(workingConfiguration.value.id, workingConfiguration.value);
      closeConfiguration();
    };

    return {
      originalConfiguration,
      workingConfiguration,
      feedColumns,
      getFeedName,
      hasConfiguration,
      getConfigurationPriority,
      getConfigurationMarkets,
      updateSort,
      updateConfiguration,
      createConfiguration,
      closeConfiguration,
      editConfiguration,
      saveConfiguration,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-table {
  &__row {
    display: flex;
    padding: 0 8px;
    background-color: inherit;
    color: inherit;
  }

  &__cell {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    padding: 0 8px;
    background-color: inherit;
    color: inherit;
    font-family: 'Rubik', sans-serif;
    font-weight: 400px;
    font-size: 14px;
    line-height: 16px;

    &:first-of-type {
      position: sticky;
      left: -16px;
    }

    &--type-competition {
      min-width: 150px;
    }

    &--type-feed {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      color: #A9A9A9;
    }

    &--type-priority {
      width: 80px;
      min-width: 80px;
      max-width: 80px;
      margin: 8px 0;
    }

    &--type-configuration {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    color: #6D6D6D;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;

    .competition-configuration-table__row {
      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #191414;

    .competition-configuration-table__row {
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      border-bottom: 1px solid #F0F0F0;
    }
  }

  &__competition-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__label {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #191414;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &--is-small {
      font-size: 12px;
      line-height: 14.22px;
    }

    &--is-muted {
      color: #6D6D6D;
    }

    &--is-clickable {
      cursor: pointer;
    }
  }

  &__sort-cell {
    display: flex;
    align-items: center;
    gap: 2px;
    width: 100%;
    height: 100%;
  }
}
</style>
