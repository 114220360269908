import {
  max,
  min,
  map,
  isFinite,
} from 'lodash';

const LEFT_OFFSET = 1;
const RIGHT_OFFSET = 1;

export const calculateNavigationRange = ({ page, totalPages }) => {
  const start = max([1, page - LEFT_OFFSET]);
  const end = min([totalPages, page + RIGHT_OFFSET]);
  const startOffset = max([0, page + RIGHT_OFFSET - totalPages]);
  const offsetStart = max([1, start - startOffset]);
  const endOffset = max([0, 1 - page - LEFT_OFFSET]);
  const offsetEnd = min([totalPages, end + endOffset]);

  const pagination = ['previous', 'next'];
  for (let number = offsetStart; number <= offsetEnd; number += 1) {
    pagination.splice(pagination.length - 1, 0, number);
  }

  if (offsetStart - 1 === 1) {
    pagination.splice(1, 0, 1);
  }
  if (offsetEnd + 1 === totalPages) {
    pagination.splice(pagination.length - 1, 0, totalPages);
  }

  if (offsetStart !== 1 && offsetStart - 1 !== 1) {
    pagination.splice(1, 0, ...[1, '...']);
  }
  if (offsetEnd !== totalPages && offsetEnd + 1 !== totalPages) {
    pagination.splice(pagination.length - 1, 0, ...['...', totalPages]);
  }

  return pagination;
};

export const createNavigationItems = ({ page, totalPages }) => {
  const values = calculateNavigationRange({ page, totalPages });
  return map(values, (value, index) => {
    if (value === 'previous') {
      return {
        id: index,
        label: '',
        value: page - 1 < 1 ? '' : page - 1,
        icon: 'chevron-left',
      };
    }

    if (value === 'next') {
      return {
        id: index,
        label: '',
        value: page + 1 > totalPages ? '' : page + 1,
        icon: 'chevron-right',
      };
    }

    return {
      id: index,
      label: value,
      value: isFinite(value) ? value : '',
    };
  });
};
