<template>
  <div
    :class="[
      'competition-configuration-table-sort',
      { 'competition-configuration-table-sort--is-asc': sortOrDefault === 'COMPETITION_NAME_ASC' },
      { 'competition-configuration-table-sort--is-desc': sortOrDefault === 'COMPETITION_NAME_DESC' },
    ]"
    @click="switchOrder"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          class="competition-configuration-table-sort__arrow-up"
          d="M11.0263 2.55279C11.4416 1.81574 12.5584 1.81574 12.9737 2.55279L16.8688 9.46554C17.2605 10.1607 16.7279 11 15.8951 11H8.10492C7.27209 11 6.73948 10.1607 7.13118 9.46554L11.0263 2.55279Z"
        />
        <path
          class="competition-configuration-table-sort__arrow-down"
          d="M12.9737 21.4472C12.5584 22.1843 11.4416 22.1843 11.0263 21.4472L7.13118 14.5345C6.73948 13.8393 7.27209 13 8.10492 13L15.8951 13C16.7279 13 17.2605 13.8393 16.8688 14.5345L12.9737 21.4472Z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue';
import { indexOf } from 'lodash';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const sortOptions = computed(() => ['COMPETITION_NAME_ASC', 'COMPETITION_NAME_DESC', 'NATURAL']);
    const sortOrDefault = computed(() => props.modelValue || sortOptions.value[0]);

    const switchOrder = () => {
      let sortIndex = indexOf(sortOptions.value, sortOrDefault.value);
      if (sortIndex === -1 || sortIndex + 1 >= sortOptions.value.length) sortIndex = -1;
      const nextSort = sortOptions.value[sortIndex + 1];
      emit('update:modelValue', nextSort);
    };

    return {
      sortOrDefault,
      switchOrder,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-table-sort {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 2px;

  &__arrow-up,
  &__arrow-down {
    fill: #CDCDCD;
  }

  &--is-asc {
    .competition-configuration-table-sort__arrow-up {
      fill: #191414;
    }

    .competition-configuration-table-sort__arrow-down {
      fill: #CDCDCD;
    }
  }

  &--is-desc {
    .competition-configuration-table-sort__arrow-up {
      fill: #CDCDCD;
    }

    .competition-configuration-table-sort__arrow-down {
      fill: #191414;
    }
  }
}
</style>
