<template>
  <div class="competition-configuration-pagination">
    <div class="competition-configuration-pagination__per-page">
      <span class="competition-configuration-pagination__label">
        Showing
      </span>
      <div
        ref="perPageRootElement"
        class="competition-configuration-pagination__dropdown"
      >
        <button
          class="competition-configuration-pagination__dropdown-toggle"
          @click="togglePerPageMenu"
        >
          {{ perPage }}
          <Icon name="chevron-selector" />
        </button>
        <ul
          v-if="isPerPageMenuOpen"
          class="competition-configuration-pagination__dropdown-menu"
        >
          <li
            v-for="option in perPageOptions"
            :key="option"
            class="competition-configuration-pagination__dropdown-option"
            @click="setPerPage(option)"
          >
            {{ option }}
            <Icon
              v-if="perPage === option"
              name="check"
            />
          </li>
        </ul>
      </div>
      <span class="competition-configuration-pagination__label">
        results per page
      </span>
      <span
        :class="[
          'competition-configuration-pagination__label',
          'competition-configuration-pagination__label--is-muted'
        ]"
      >
        ({{ totalCount }} in total)
      </span>
    </div>
    <div class="competition-configuration-pagination__navigation">
      <div
        v-for="item in navigationItems"
        :key="item.id"
        :class="[
          'competition-configuration-pagination__navigation-item',
          { 'competition-configuration-pagination__navigation-item--is-disabled': !item.value },
          { 'competition-configuration-pagination__navigation-item--is-selected': page === item.value },
        ]"
        @click="setPage(item.value)"
      >
        <Icon
          v-if="item.icon"
          class="competition-configuration-pagination__navigation-icon"
          :name="item.icon"
        />
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { ceil } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { createNavigationItems } from '@/services/helpers/pagination';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  emits: {
    'update:page': {
      type: Number,
    },
    'update:perPage': {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const totalPages = computed(() => ceil(props.totalCount / props.perPage));

    const perPageRootElement = ref();
    const isPerPageMenuOpen = ref(false);
    const perPageOptions = [10, 20, 50, 100];

    const togglePerPageMenu = () => {
      isPerPageMenuOpen.value = !isPerPageMenuOpen.value;
    };
    const setPerPage = (newValue) => {
      emit('update:perPage', newValue);
      isPerPageMenuOpen.value = false;
    };

    onClickOutside(perPageRootElement, () => {
      isPerPageMenuOpen.value = false;
    });

    const navigationItems = computed(() => createNavigationItems({
      page: props.page,
      totalPages: totalPages.value,
    }));
    const setPage = (newValue) => {
      if (!newValue) return;
      emit('update:page', newValue);
    };

    return {
      totalPages,
      perPageRootElement,
      isPerPageMenuOpen,
      perPageOptions,
      togglePerPageMenu,
      setPerPage,
      navigationItems,
      setPage,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  gap: 16px;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  border-top: 1px solid #F0F0F0;
  position: sticky;
  bottom: 0;
  background: #fff;

  &__label {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #191414;

    &--is-muted {
      color: #6D6D6D;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;

    &-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      background-color: #fff;
      color: #191414;
      border: 1px solid #f0f0f0;
      padding: 8px;
      gap: 4px;
      border-radius: 4px;
      cursor: pointer;
      height: 32px;

      .icon {
        fill: #191414;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px #A2BFFF;
        outline: none;
      }
    }

    &-menu {
      position: absolute;
      bottom: calc(100% + 4px);
      background-color: #fff;
      color: #191414;
      box-shadow: 0 4px 8px rgba(0,0,0,.08);
      border-radius: 4px;
      margin-bottom: 4px;
      padding: 4px 0;
      border: 1px solid #f0f0f0;
      min-width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      z-index: 1000;
      overflow: hidden;
    }

    &-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: #fafafa;
      }

      .icon {
        width: 16px;
        height: 16px;
        padding: 2px;
        stroke: #000;
      }
    }
  }

  &__per-page {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__navigation {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      min-height: 24px;
      max-height: 24px;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      user-select: none;
      color: #6d6d6d;
      stroke: #6d6d6d;

      &:hover {
        color: #323232;
        stroke: #323232;
      }

      &--is-disabled {
        cursor: default;
        user-select: initial;
        color: #A9A9A9;
        stroke: #A9A9A9;

        &:hover {
          color: #A9A9A9;
          stroke: #A9A9A9;
        }
      }

      &--is-selected {
        color: #191414;
        stroke: #191414;
      }
    }

    &-icon {
      stroke: inherit;

      svg {
        stroke: inherit;
      }
    }
  }
}
</style>
