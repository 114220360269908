<template>
  <teleport to="body">
    <div class="competition-configuration-modal">
      <div
        class="competition-configuration-modal__window"
        ref="competitionConfigurationModalRef"
      >
        <div class="competition-configuration-modal__window-header">
          <span class="competition-configuration-modal__window-title">
            {{ competitionName }}
            configuration
          </span>
          ({{ feedName }})
        </div>
        <div class="competition-configuration-modal__window-content">
          <div class="competition-configuration-modal__toggle-box">
            <div
              class="competition-configuration-modal__toggle-control"
              @click="setCoverageExpected(!coverageExpected)"
            >
              <Toggle
                :model-value="coverageExpected"
                @update:model-value="setCoverageExpected"
              />
              Override coverage available
            </div>
          </div>
          <div class="competition-configuration-modal__form">
            <div class="competition-configuration-modal__form-group">
              <div class="competition-configuration-modal__form-label">
                {{ feedName }} priority
              </div>
              <div class="competition-configuration-modal__form-control">
                <CompetitionConfigurationPriorityField
                  variant="modal"
                  :model-value="priority"
                  :max="9"
                  @update:model-value="setPriority"
                />
              </div>
            </div>
            <div class="competition-configuration-modal__form-group">
              <div
                :class="[
                  'competition-configuration-modal__form-label',
                  'competition-configuration-modal__form-label--size-lg',
                ]"
              >
                {{ feedName }}
              </div>
              <div class="competition-configuration-modal__form-control">
                <div class="competition-configuration-modal__checkbox-list">
                  <div
                    v-for="option in allMarketCategories"
                    :key="option.id"
                    class="competition-configuration-modal__checkbox-control"
                    @click="toggleMarketCategory(option.id)"
                  >
                    <Checkbox
                      :model-value="isMarketCategoryEnabled(option.id)"
                      @update:model-value="toggleMarketCategory(option.id)"
                    />
                    {{ option.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="competition-configuration-modal__window-footer">
          <Button
            @click="close"
          >
            Dismiss
          </Button>
          <Button
            variant="primary"
            :disabled="isSubmitDisabled"
            @click="submit"
          >
            {{ submitLabel }}
          </Button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  isEqual,
  includes,
  indexOf,
  capitalize,
} from 'lodash';
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { allMarketCategories } from '@/services/helpers/competition-configuration';
import Button from '@/components/common/Button';
import Toggle from '@/components/common/Toggle';
import Checkbox from '@/components/common/Checkbox';
import CompetitionConfigurationPriorityField from './CompetitionConfigurationPriorityField';

export default {
  components: {
    Button,
    Toggle,
    Checkbox,
    CompetitionConfigurationPriorityField,
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    originalConfiguration: {
      type: Object,
      default: null,
    },
    feeds: {
      type: Array,
      required: true,
    },
  },
  emits: {
    'update:configuration': {
      type: Object,
    },
    submit: {},
    close: {},
  },
  setup(props, { emit }) {
    const competitionName = computed(() => props.configuration.competitionName);
    const feedName = computed(() => capitalize(props.configuration.feed));
    const coverageExpected = computed(() => props.configuration.coverageExpected);
    const priority = computed(() => props.configuration.priority);
    const isValidPriority = computed(() => `${priority.value}`.length && (+priority.value >= 0 && +priority.value <= 9));
    const marketCategories = computed(() => props.configuration.marketCategories);
    const submitLabel = computed(() => (props.originalConfiguration ? 'Save configuration' : 'Add configuration'));
    const isSubmitDisabled = computed(() => !isValidPriority.value || isEqual(props.configuration, props.originalConfiguration));

    const setCoverageExpected = (newCoverageExpected) => {
      emit('update:configuration', {
        ...props.configuration,
        coverageExpected: newCoverageExpected,
      });
    };
    const setPriority = (newPriority) => {
      emit('update:configuration', {
        ...props.configuration,
        priority: newPriority,
      });
    };
    const isMarketCategoryEnabled = (marketCategoryCode) => includes(marketCategories.value, marketCategoryCode);
    const toggleMarketCategory = (marketCategory) => {
      const index = indexOf(marketCategories.value, marketCategory);
      const newMarketCategories = [...marketCategories.value];

      if (index === -1) {
        newMarketCategories.push(marketCategory);
      } else {
        newMarketCategories.splice(index, 1);
      }

      emit('update:configuration', {
        ...props.configuration,
        marketCategories: newMarketCategories,
      });
    };

    const submit = () => {
      emit('submit');
    };
    const close = () => {
      emit('close');
    };

    const competitionConfigurationModalRef = ref(null);

    onClickOutside(competitionConfigurationModalRef, () => {
      close();
    });

    return {
      allMarketCategories,
      competitionName,
      feedName,
      coverageExpected,
      priority,
      marketCategories,
      submitLabel,
      isSubmitDisabled,
      setCoverageExpected,
      setPriority,
      isMarketCategoryEnabled,
      toggleMarketCategory,
      submit,
      close,
      competitionConfigurationModalRef,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: transparentize(#191414, 0.5);
  stroke: #000;

  &__window {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    width: 360px;
    height: 392px;

    &-header {
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #F0F0F0;
      font-family: 'Poppins', sans-serif;
      font-size: 15.72px;
      font-weight: 600;
      line-height: 23.58px;
      gap: 4px;
    }

    &-content {
      flex: 1;
      width: 100%;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-footer {
      display: flex;
      align-items: center;
      padding: 16px;
      border-top: 1px solid #F0F0F0;
      margin-left: auto;
      gap: 4px;
    }

    &-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__toggle-box {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #FAFAFA;
  }

  &__toggle-control {
    display: flex;
    gap: 8px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #191414;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__checkbox-control {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #191414;
    cursor: pointer;
    user-select: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 160px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #6D6D6D;
      text-transform: uppercase;

      &--size-lg {
        font-size: 12px;
        line-height: 14.22px;
        padding-bottom: 6px;
      }
    }
  }
}
</style>
