<template>
  <div class="competition-configuration-search">
    <Icon
      class="competition-configuration-search__icon"
      name="search"
    />
    <input
      class="competition-configuration-search__field"
      type="text"
      placeholder="Search competition"
      :value="modelValue"
      @input="setModelValue($event.target.value)"
    >
  </div>
</template>

<script>
import { debounce } from 'lodash';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: 300,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const setModelValue = debounce((newModelValue) => {
      emit('update:modelValue', newModelValue);
    }, props.debounce);

    return {
      setModelValue,
    };
  },
};
</script>

<style lang="scss">
.competition-configuration-search {
  display: flex;
  width: 240px;
  height: 32px;
  min-width: var(--width-large, 44px);
  flex-direction: row;
  align-items: center;
  position: relative;

  &__icon {
    position: absolute;
    left: 8px;
    width: 16px;
    height: 16px;
    stroke: #CDCDCD;
  }

  &__field {
    width: 100%;
    padding: 8px 8px 8px 32px;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    height: 32px;
    font-size: 14px;

    &::placeholder {
      color: #CDCDCD;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }
  }
}
</style>
