<template>
  <Sidebar
    class="competition-configuration-sidebar"
    :expanded="expanded"
    @update:expanded="setExpanded"
  >
    <SidebarButton
      v-for="option in options"
      :key="option.id"
      :selected="modelValue === option.id"
      @click="setModelValue(option.id)"
    >
      {{ option.name }}
    </SidebarButton>
  </Sidebar>
</template>

<script>
import Sidebar from '@/components/common/Sidebar';
import SidebarButton from '@/components/common/SidebarButton';

export default {
  components: {
    Sidebar,
    SidebarButton,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
    'update:expanded': {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const setModelValue = (newModelValue) => {
      emit('update:modelValue', newModelValue);
    };
    const setExpanded = (newIsExpanded) => {
      emit('update:expanded', newIsExpanded);
    };

    return {
      setModelValue,
      setExpanded,
    };
  },
};
</script>
